import "../css/layouts.css";
import Navigation from "../Navigation";
import {
    GovAccordionItem,
    GovBreadcrumbs,
    GovButton, GovFormControl,
    GovFormFile, GovFormLabel, GovFormSelect,
    GovIcon, GovLoading, GovModal, GovPrompt,
    GovSkeleton,
    GovSpacer
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    convertIsoToDateFormat,
    convertIsoToCustomFormat, getIcon, mapBuildingPurpose,
    mapConsultationResult, mapFinancingType,
    mapProjectPhase,
    mapProjectType, parseJwt, typeToText,
    userRoleToText, externalRoleToText, convertUtilizationToPercentage, convertDateToDDMMYYYY
} from "../utils/utils";
import {faFileAlt, faFileExcel, faFilePdf, faFileWord} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function ProjectDetailContent() {
    const {projectId } = useParams();
    const jwt = localStorage.getItem('jwt');
    const [project, setProject] = useState();
    const [rcPerson, setRcPerson] = useState();
    const [contactPerson, setContactPerson] = useState();
    const [documents, setDocuments] = useState();
    const [documentToUploadType, setDocumentToUploadType] = useState(1);
    const [documentIsLoading, setDocumentIsLoading] = useState(false);
    const [town, setTown] = useState();
    const [preProjectConsultations, setPreProjectConsultations] = useState();

    const user = parseJwt(localStorage.getItem("jwt"))
    let role
    if(user !== null){
        role = user.role[0].authority
    }

    const fetchData = () => {
        return fetch(process.env.REACT_APP_API_URL+"/projects/"+ projectId, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                console.log(jsonData)
                setProject(jsonData)
                setContactPerson(jsonData.contactPerson)
                setTown(jsonData.town)
                setDocuments(jsonData.documents)
                setPreProjectConsultations(jsonData.preProjectConsultations)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const fetchNewDocuments = () => {
        return fetch(process.env.REACT_APP_API_URL+"/projects/"+ projectId, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setDocuments(jsonData.documents)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (project) {
            fetchResponsiblePerson();
            // fetchTown();
        }
    }, [project]);


    function fetchResponsiblePerson() {
        fetch(process.env.REACT_APP_API_URL+"/users/" + project.assignedRcEmployeeId, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setRcPerson(jsonData)
                console.log(jsonData)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    function uploadDocument(file, type) {
        if (file.name.length > 350){
            alert("Název souboru je příliš dlouhý, zkraťte ho prosím.")
            return
        }
        setDocumentIsLoading(true)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('targetEntityId', projectId);
        formData.append('type', parseInt(type));
        formData.append('target', "PROJECT");

        fetch(`${process.env.REACT_APP_API_URL}/document/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setDocumentIsLoading(false)
                console.log(jsonData);
                fetchNewDocuments();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }


    const typeIdArray = Array.from({length: 7}, (_, i) => i + 1); // Array of all possible type IDs from 1 to 6



    const projectDocumentTypeMapper = {
        1: "Zápisy",
        2: "Projektová dokumentace",
        3: "Karta projektu",
        4: "Kalkulace",
        5: "Rozpočet",
        6: "Výběrová řízení",
        7: "Jiný dokument"
};



    const [personName, setPersonName] = useState('');
    const [personSurname, setPersonSurname] = useState('');
    const [personTelephone, setPersonTelephone] = useState('');
    const [personEmail, setPersonEmail] = useState('');
    const [personRole, setPersonRole] = useState('');
    const [personOrganization, setPersonOrganization] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const [startOfConsultation, setStartOfConsultation] = useState('');
    const [endOfConsultation, setEndOfConsultation] = useState('');

    const [lengthOfConsultation, setLengthOfConsultation] = useState('');
    const [openPoints, setOpenPoints] = useState('');
    const [agreedPoints, setAgreedPoints] = useState('');
    let navigate = useNavigate();

    const [personArray, setPersonArray]=useState([
    ])

    const handlePersonNameChange = (event) => {
        setPersonName(event.target.value);
    };
    const handlePersonSurnameChange = (event) => {
        setPersonSurname(event.target.value);
    };
    const handlePersonTelephoneChange = (event) => {
        setPersonTelephone(event.target.value);
    };
    const handlePersonEmailChange = (event) => {
        setPersonEmail(event.target.value);
    };
    const handlePersonOrganizationChange = (event) => {
        setPersonOrganization(event.target.value);
    };
    const handlePersonRoleChange = (event) => {
        setPersonRole(event.target.value);
    };

    const handleStartOfConsultationChange = (event) => {
        setStartOfConsultation(event.target.value);
        console.log(event.target.value)
    }
    const handleEndOfConsultationChange = (event) => {
        setEndOfConsultation(event.target.value);
        console.log(event.target.value)
    }

    const handleDocumentToUploadTypeChange = (event) => {
        console.log(event.target.value)
        setDocumentToUploadType(event.target.value);
    }
    const handleAgreedPointsChange = (event) => {
        setAgreedPoints(event.target.value);
    }
    const [selectedExternalPersons, setSelectedExternalPersons] = useState([]);
    const handleSelectExternalPersonsChange = (event) => {
        console.log(event)
        console.log(selectedExternalPersons)
        setSelectedExternalPersons(Array.from(event.detail.value, option => parseInt(option.value)));
    };


        function fetchDocument(documentID, documentName) {
            fetch(`${process.env.REACT_APP_API_URL}/document/${documentID}/download`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    // Check if the MIME type of the blob is a PDF or CSV
                    if (blob.type === 'application/pdf') {
                        // Open the file in a new tab
                        window.open(url);
                    } else {
                        // Download the file
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = documentName;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                })

        }

    function deleteDocument(documentID) {
            fetch(process.env.REACT_APP_API_URL + "/document/" + documentID, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response;
                })
                .then(jsonData => {
                    console.log(jsonData)
                    fetchNewDocuments()
                })
                .catch(error => {
                    console.error('Error:', error);
                });
    }

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [notAuthorizedModalOpen, setNotAuthorizedModalOpen] = useState(false);

    function openDeleteModal(){
        setDeleteModalOpen(true)
    }
    function closeDeleteModal(){
        setDeleteModalOpen(false)
    }

    function openNotAuthorizedModal(){
        setNotAuthorizedModalOpen(true)
    }
    function closeNotAuthorizedModal(){
        setNotAuthorizedModalOpen(false)
        setDeleteModalOpen(false)
    }

    function deleteProject(){
        if (role === "HEADQUARTERS") {
            fetch(process.env.REACT_APP_API_URL + `/projects/${projectId}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                method: 'DELETE',
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    if (response.status === 204) {
                        navigate(`/towns/detail/${town.id}`)
                    } else {
                        return response.json().then(data => {
                            throw new Error(`Unexpected response: ${data.message || 'No message provided'}`);
                        });
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } else {
            setNotAuthorizedModalOpen(true)
        }
    }


    return (
        <div>
            <Navigation active={"villages"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/towns/search">Obce</a></li>


                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            {town ?
                                <a href={`/towns/${town.id.toString().length > 6 ? "collective/" : ""}detail/${town.id}`}>
                                    {town.name} {town.id.toString().length > 6 && "(DSO)"}</a> :
                                <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                             animation="progress" count="1" width="300px"
                                             height="30px"></GovSkeleton>
                            }
                        </li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Detail projektu ({projectId})</strong></li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">

                        <div className="gov-flexbox">
                            <div className="gov-title--beta">Detail projektu</div>
                            <div className="gov-flexbox--justify-end">
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           size="m" href={`/edit-project/${projectId}`}>Upravit
                                </GovButton>
                                {(role === "HEADQUARTERS") &&
                                <GovButton size="m" type="solid" wcag-label="Smazat" variant="error"
                                           onGov-click={openDeleteModal}>Smazat
                                </GovButton>
                                }
                            </div>
                            <GovPrompt open={deleteModalOpen} onGov-close={closeDeleteModal}
                                       label="Smazat projekt" role="dialog" label-tag="h5"
                                       wcag-close-label="Closes the prompt to delete the file my-photo.jpg">Opravdu
                                si přejete smazat tento projekt?<br/>Tato operace je nevratná.
                                <GovButton onGov-click={closeDeleteModal} variant="primary" size="m" type="base"
                                           slot="actions"
                                           wcag-label="Cancel delete file my-photo.jpg">Zrušit
                                </GovButton>
                                <GovButton variant="error" size="m" type="solid" slot="actions"
                                           wcag-label="Potvrdit smazání" onGov-click={deleteProject}>Smazat
                                </GovButton>
                            </GovPrompt>
                            <GovModal open={notAuthorizedModalOpen} onGov-close={closeNotAuthorizedModal} label="Nedostatečné oprávnění"
                                      wcag-close-label="Close dialog box with more information">
                                <p>Na provedení akce nemáte dostatečné oprávnění</p>
                            </GovModal>
                        </div>

                        <div className="form-help-layout gov-tabs">
                        <div className="gov-tabs__content-holder ">
                                <div className="gov-tabs__content is-active" role="tabpanel">
                                    {(project && town) ?
                                        <div>
                                            <div className="project-detail">
                                                <div>
                                                    <h4>Název obce</h4>
                                                    <p>{town.name}</p>
                                                </div>
                                                <div>
                                                    <h4>Kód obce</h4>
                                                    <p>{town.id}</p>
                                                </div>
                                                <div>
                                                    <h4>Název projektu</h4>
                                                    <p>{project.name}</p>
                                                </div>
                                                <div>
                                                    <h4>Typ projektu (převažující v případě mixu)</h4>
                                                    <p>{mapProjectType(project.type)}</p>
                                                </div>
                                                <div>
                                                    <h4>Subjekt/žadatel</h4>
                                                    <p>{project.projectSubject && project.projectSubject !== "NOT_SELECTED" ? project.projectSubject.name + " - " + typeToText(project.projectSubject.type) : "Žádný subjekt není přiřazen"}</p>
                                                </div>
                                                <div>
                                                    <h4>Absorpční kapacita</h4>
                                                    <p>{project.includedAbsorptionCapacity === true ? "Projekt je zahrnut v absorpční kapacitě" : "Projekt není zahrnut v absorpční kapacitě"}</p>
                                                </div>
                                                <div>
                                                    <h4>Počet bytových jednotek, které díky projektu vzniknou</h4>
                                                    <p>{project.housingUnitsCount ? project.housingUnitsCount : "Tato hodnota aktuálně chybí"}</p>
                                                </div>
                                                <div>
                                                    <h4>Z toho počet jiných bytových jednotek (sociální/bezbariérových),
                                                        které
                                                        díky projektu vzniknou z celkového počtu</h4>
                                                    <p>{project.otherHousingUnitsCount ? project.otherHousingUnitsCount : "Tato hodnota aktuálně chybí"}</p>
                                                </div>
                                                <div>
                                                    <h4>Počet m2 hrubé podlažní plochy (HPP) celého objektu</h4>
                                                    <p>{project.builtArea ? project.builtArea : "Tato hodnota aktuálně chybí"}</p>
                                                </div>
                                                <div>
                                                    <h4>Počet m2 čisté podlažní plochy (ČPP) celého objektu</h4>
                                                    <p>{project.netBuiltArea ? project.netBuiltArea : "Tato hodnota aktuálně chybí"}</p>
                                                </div>
                                                <div>
                                                    <h4>Počet m2 čisté podlažní plochy (ČPP) bytů</h4>
                                                    <p>{project.netFlatsArea ? project.netFlatsArea : "Tato hodnota aktuálně chybí"}</p>
                                                </div>
                                                <div>
                                                    <h4>Očekávaná výše investice v mil. Kč</h4>
                                                    <p>{project.investmentAmount ? `${project.investmentAmount.toLocaleString()} mil. Kč` : "Tato hodnota aktuálně chybí"} </p>
                                                </div>
                                                <div>
                                                    <h4>Nákladové nájemné (Kč/m2/měsíc)</h4>
                                                    <p>{project.expenseRent ? project.expenseRent : "Tato hodnota aktuálně chybí"}</p>
                                                </div>
                                                <div>
                                                    <h4>Typ financování (převažující)</h4>
                                                    <p>{project.financing || project.financing === 0 ? mapFinancingType(project.financing) : "Tato hodnota aktuálně chybí"}</p>
                                                </div>
                                                <div>
                                                    <h4>Účel bytové výstavby u projektu</h4>
                                                    <p>{project.purpose || project.purpose === 0 ? mapBuildingPurpose(project.purpose) : "Tato hodnota aktuálně chybí"}</p>
                                                </div>
                                                <div>
                                                    <h4>Datum vydání stavebního povolení</h4>
                                                    <p>{project.buildingPermitDate ? convertIsoToDateFormat(project.buildingPermitDate) : "Tato hodnota aktuálně chybí"}</p>
                                                </div>
                                                {rcPerson &&
                                                    <div>
                                                        <h4>Přiřazená osoba k projektu</h4>
                                                        <p>{rcPerson.firstname + " " + rcPerson.lastname + " " + "(" + rcPerson.loginEmail + ")" + " - " + userRoleToText(rcPerson.role)}</p>
                                                    </div>
                                                }
                                                {contactPerson &&
                                                    <div>
                                                        <h4>Kontaktní osoba k projektu</h4>
                                                        <p>{contactPerson.firstname + " " + contactPerson.lastname + " " + "(" + contactPerson.email + ", " + contactPerson.phone + ")" + " - " + externalRoleToText(contactPerson.role)}</p>
                                                    </div>
                                                }
                                                <div>
                                                    <h4>Živý projekt</h4>
                                                    <p>{project.active === true ? "Projekt je živý" : "Projekt není živý"}</p>
                                                </div>
                                                <div>
                                                    <h4>Důvěrné informace</h4>
                                                    <p>{project.confidential === true ?
                                                        <span><strong>Ano</strong></span> :
                                                        <span><strong>Ne</strong></span>}</p>
                                                </div>
                                                        <div>
                                                    <h4>Podaná žádost - Subkomponenta 4.1.3</h4>
                                                    <p>{project.request413 === true ?
                                                        <span>Žádost <strong>byla</strong> podána</span> :
                                                        <span>Žádost <strong>nebyla</strong> podána</span>}</p></div>
                                                <div>
                                                    <h4>Podaná žádost o financování díky činnosti RC</h4>
                                                    <p>{project.requestSubmitted === true ?
                                                        <span>Žádost o financování <strong>byla</strong> podána</span> :
                                                        <span>Žádost o financování <strong>nebyla</strong> podána</span>}</p></div>
                                                <div>
                                                    <h4>Podaná žádost o financování díky činnosti RC</h4>
                                                    <p>{project.requestSubmitted === true ? "Žádost o financování byla podána" : "Žádost o financování nebyla podána"}</p>
                                                </div>
                                                <div>
                                                    <h4>Pravděpodobnost realizace projektu</h4>
                                                    <p>{project.realizationProbability ? convertUtilizationToPercentage(project.realizationProbability) + "%" : "Pravděpodobnost není uvedena"}</p>
                                                </div>

                                            </div>
                                            <div>
                                                {project.projectPhaseTimeTrackings && (
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                        <th>Fáze projektu</th>
                                                            <th>Od</th>
                                                            <th>Do</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {project.projectPhaseTimeTrackings
                                                            .sort((a, b) => new Date(a.from) - new Date(b.from))
                                                            .map((tracking, index) => (
                                                                <tr key={index}>
                                                                    <td>{mapProjectPhase(tracking.phase)}
                                                                        {tracking.to === null && (
                                                                            <strong> (aktuální)</strong>
                                                                        )}
                                                                    </td>
                                                                    <td>{convertDateToDDMMYYYY(tracking.from)}</td>
                                                                    <td>{tracking.to ? convertDateToDDMMYYYY(tracking.to) : "N/A"}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                )}
                                            </div>
                                            <div>
                                                <h4>Popis projektu</h4>
                                                <p>{project.description ? project.description : "Popis projektu není uveden"}</p>
                                            </div>
                                            <div>
                                                <h4>Poznámky k projektu</h4>
                                                <p>{project.notes ? project.notes : "Poznámky k projektu nejsou uvedeny"}</p>
                                            </div>
                                            {preProjectConsultations && preProjectConsultations.length > 0 && (
                                                <div>
                                                    <h4>Předprojektové konzultace</h4>
                                                    <table cellSpacing="0" cellPadding="0">
                                                        <thead>
                                                        <tr>
                                                            <th>Datum konání</th>
                                                            <th>Výsledek</th>
                                                            <th>Odkaz na konzultaci</th>
                                                            <th></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {preProjectConsultations.map((row, index) => (
                                                            <tr key={index}>
                                                                <td className="first">{convertIsoToCustomFormat(row.start)}</td>
                                                                <td>{mapConsultationResult(row.result)}</td>
                                                                <td><a value={index} variant="primary" type="link"
                                                                       size="m"
                                                                       href={`/new-consultation/detail/${row.id}`}>Detail
                                                                    konzultace</a></td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>)}


                                            <div className="gov-title--beta">Dokumenty</div>
                                            {documentIsLoading &&
                                                <div>
                                                    <GovLoading>Prosím vyčkejte soubor se nahrává</GovLoading>
                                                </div>
                                            }

                                            <div>
                                                {typeIdArray.sort().map((typeId) => {
                                                    const docsOfType = documents.filter(doc => doc.typeId === typeId);
                                                    return (
                                                        <div key={typeId}>
                                                            <h4>{projectDocumentTypeMapper[typeId]}</h4>
                                                            {docsOfType.map((document, index) => (
                                                                <div>
                                                                    <div className="mt-10 mb-10">
                                                                        <FontAwesomeIcon icon={getIcon(document.name)}/>
                                                                        <span> </span>
                                                                        <a key={index} href={document.link}
                                                                           onClick={(event) => {
                                                                               event.preventDefault();
                                                                               fetchDocument(document.id, document.name);
                                                                           }}>
                                                                            {document.name}
                                                                        </a>
                                                                        <GovButton className="ml-5"
                                                                                   wcag-label="What exactly happens when you click?"
                                                                                   variant="primary"
                                                                                   type="solid" size="xs"
                                                                                   onGov-click={() => deleteDocument(document.id)}>
                                                                            Smazat soubor
                                                                        </GovButton>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    );

                                                })}
                                            </div>


                                            <div>

                                                <GovAccordionItem variant="primary" size="xs">
                                                    <div className="mb-10">
                                                        <h3>Nahrání souboru</h3>
                                                        <div className="sfpi-project-document-layout">
                                                        <div>
                                                                    <p>Vyberte typ nahrávaného souboru a poté přiložte soubor</p>

                                                                <GovFormControl>
                                                                    <GovFormSelect size="s" variant="secondary"
                                                                                   value={documentToUploadType} onGov-change={handleDocumentToUploadTypeChange}>
                                                                        {Object.entries(projectDocumentTypeMapper).map(([key, documentType]) => (
                                                                            <option key={key} value={key}>{documentType}</option>
                                                                        ))}
                                                                    </GovFormSelect>
                                                                    <GovFormLabel slot="top" size="m">Typ nahrávaného souboru</GovFormLabel>
                                                                </GovFormControl>
                                                                </div>
                                                                <GovFormFile
                                                                    onGov-add-file={(event) => uploadDocument(event.detail.file.file, documentToUploadType)}
                                                                    max-file-size="616448"
                                                                    accept=".pdf,.xls,.xlsx,.doc,.docx,.zip"
                                                                    expanded="true"
                                                                    displayAttachments="false">
                                                                    <p>
                                                                        <GovButton variant="primary" size="m"
                                                                                   type="outlined">Nahrát soubor ze
                                                                            zařízení
                                                                        </GovButton>
                                                                    </p>
                                                                    <GovSpacer size=""></GovSpacer>
                                                                    <p className="gov-text--s">Podporované formáty
                                                                        PDF,
                                                                        DOC, XLS, ZIP</p></GovFormFile>
                                                            </div>
                                                        </div>
                                                        <p slot="label">Nahrání souboru</p>
                                                    </GovAccordionItem>
                                                </div>

                                        </div>
                                        :
                                        <GovSkeleton wcag-label="Loading content of page" variant="secondary"
                                                     shape="text" animation="progress" count="1" width="300px"
                                                     height="30px"></GovSkeleton>}
                                    {/*{rcPeople.length > 0 &&*/}
                                    {/*    <div>*/}
                                    {/*        {rcPeople.map((person) => (*/}
                                    {/*            <div className="gov-layout gov-layout--ratio-1-2">*/}
                                    {/*                <div className="gov-layout__col gov-layout__col--pos-left">*/}
                                    {/*                    <p><b>Jméno a*/}
                                    {/*                        příjmení:</b> {person.name + " " + person.surname}*/}
                                    {/*                    </p>*/}
                                    {/*                    <p><b>Telefon:</b> {person.telephone}</p>*/}
                                    {/*                    <p><b>E-mail:</b> {person.email}</p>  <p>*/}
                                    {/*                    <b>Organizace:</b> {person.organization}</p>*/}
                                    {/*                    <p><b>Role:</b> {roleToText(person.role)}</p></div>*/}
                                    {/*                <div*/}
                                    {/*                    className="gov-layout__col gov-layout__col--pos-right gov-layout__col--spread-1">*/}
                                    {/*                    <GovButton variant="primary" size="m" type="solid"*/}
                                    {/*                               wcag-label="Open dialog box for more information"*/}
                                    {/*                               onGov-click={() => deletePerson(person.id)}>Odebrat*/}
                                    {/*                    </GovButton>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        ))}*/}
                                    {/*        <hr/>*/}
                                    {/*    </div>*/}
                                    {/*}*/}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
